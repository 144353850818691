function initializeAutocomplete() {
    const addressField = document.querySelector('#places-autocomplete');

    addAutocomplete(addressField);
}

function addAutocomplete(inputField) {
    const options = {
        types: ['address'],
    };

    if (inputField) {
        const autocomplete = new google.maps.places.Autocomplete(inputField, options);

        google.maps.event.addListener(autocomplete, "place_changed", function () {
            prepareSubmission(autocomplete);
        });

        // Don't allow submission via enter (as it won't trigger place_changed)
        inputField.addEventListener("keydown", function (event) {
            if (event.keyCode === 13) {
                event.preventDefault(); // TODO: Pick (first) selection on enter & submit.
            }
        });
    }
}

function prepareSubmission(autocomplete) {
    const transalation = {
        'country': 'country',
        'locality': 'city',
        'postal_town': 'city',
        'postal_code': 'zip',
        'administrative_area_level_1': 'state',
    };

    const fields = Object.keys(transalation);
    const place = autocomplete.getPlace();
    
    for (let i = 0; i < place.address_components.length; i++) {
        const addressTypes = place.address_components[i].types;
        const match = addressTypes.filter(v => fields.includes(v));

        if (match[0] && match[0] === 'country') {
            $(`[name=${transalation[match[0]]}]`).val(place.address_components[i].long_name);
        } else if (match[0]) {
            $(`[name=${transalation[match[0]]}]`).val(place.address_components[i].short_name);
        }
    }
    
    const placeStreetNum = place.address_components.filter(e => e.types.includes('street_number'))[0];

    let streetNum = "";
    if (streetNum) {
        streetNum = placeStreetNum
    }

    streetNum = streetNum ? streetNum.concat(' ') : ''

    const placeRoute = place.address_components.filter(e => e.types.includes('route'))[0];

    if (placeRoute) {
        route = placeRoute.short_name;
    }

    $(`[name="address"]`).val(streetNum.concat(route));
}
